@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  html, body , div#root {
    height: 100%;
  }
}

@layer components {
  .custom-input {
    @apply py-2 px-4 bg-white text-sm text-gray-800 disabled:bg-[#EFEFEF] border-[1px] border-gray-200 rounded-md focus-visible:!outline-none focus:!outline-none !shadow-none !ring-0 focus:!border-gray-200;
  }
  .button-primary {
    @apply bg-primary-400 px-2 text-white font-medium shadow rounded-md border-primary-400 hover:bg-primary-500 hover:border-primary-500;
  }
  .button-primary.with-loading {
    @apply disabled:text-white disabled:border-primary-300 disabled:bg-primary-300;
  }
  .button-secondary {
    @apply bg-amber-500 px-2 text-white font-medium shadow rounded-md border-amber-500 hover:bg-amber-600 hover:border-amber-600;
  }
  .button-error {
    @apply bg-red-500 px-2 text-white font-medium shadow rounded-md border-red-500 hover:bg-red-600 hover:border-red-600;
  }
  .button-error.with-loading {
    @apply disabled:text-white disabled:border-red-300 disabled:bg-red-300;
  }
  .button-secondary-outline {
    @apply text-gray-700 border-gray-300 bg-white hover:bg-white hover:border-gray-400;
  }
  .button-secondary-outline.with-loading {
    @apply disabled:text-white disabled:border-gray-300 disabled:bg-gray-300;
  }

}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.my-select__input {
  box-shadow: none !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

body .react-datepicker-popper {
  z-index: 9999;
}